import { Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useCallback, useContext, useState } from "react";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import IETask from "../../models/IETask";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";

export interface IETaskParams {
    l11: string,
    l10: string,
    modelFamily: string
}

interface TasksDialogProps {
    params: IETaskParams,
    setParams: (params: IETaskParams) => void,
    refetch: () => void
}

export default function TasksDialog(props: TasksDialogProps) {
    const { params, setParams, refetch } = props;
    const [tasks, setTasks] = useState<IETask[]>([]);
    const { t, snackbar } = useContext(LayoutContext);

    useQuery<IETask[]>(["ieTasks", params.l10, params.l11, params.modelFamily], ({ signal }) => fetchGet(`${apiUrl}/v1/IETasks/?l10=${params.l10}&l11=${params.l11}&modelFamily=${params.modelFamily}`, signal), {
        enabled: !isEmpty(params),
        onSuccess: (data) => setTasks(data)
    });

    const toggleTaskAnswer = useCallback((task: IETask) => () => {
        task.answer = !task.answer;

        const req = {
            l10: params.l10,
            l11: params.l11,
            task: task.taskName
        };

        const methodUrl: string = task.answer ? "SetFinished" : "SetUnfinished";
        fetchPost(`${apiUrl}/v1/IETasks/${methodUrl}/?l10=${req.l10}&l11=${req.l11}&task=${req.task}`, null)
            .catch(error => {
                if (typeof (error) === "string") {
                    snackbar({ message: error, severity: "error" });
                }
            });

        const updatedTasks: IETask[] = tasks.map(t => t.taskName === task.taskName ? task : t);
        setTasks(updatedTasks);
    }, [tasks, params]);

    const resetTasks = useCallback(() => {
        const req = {
            l10: params.l10,
            l11: params.l11
        };

        fetchPost(`${apiUrl}/v1/IETasks/reset/?l10=${req.l10}&l11=${req.l11}`, null)
            .catch(error => {
                if (typeof (error) === "string") {
                    snackbar({ message: error, severity: "error" });
                }

                setTasks([]);
            });
    }, [params]);

    const onClose = useCallback(() => {
        setParams({} as IETaskParams);
        setTasks([]);
        refetch();
    }, []);

    return (
        <CustomDialog open={!isEmpty(params)} onClose={onClose} title={t("Tasks")} maxWidth="md" color="primary" dialogActions={[
            <Button key="reset" onClick={resetTasks} color="primary">{t("ResetTasks")}</Button>
        ]}>
            <List disablePadding>
                {tasks.map(task =>
                    <ListItem key={task.taskName} disablePadding>
                        <ListItemButton onClick={toggleTaskAnswer(task)} dense>
                            <ListItemIcon>
                                <Checkbox edge="start" checked={task.answer} disableRipple />
                            </ListItemIcon>
                            <ListItemText primary={task.taskName} />
                        </ListItemButton>
                    </ListItem>
                )}

                {!tasks.length && <Typography sx={{ textAlign: 'center', mt: 2 }}>{t("NoAvailableTasks")}</Typography>}
            </List>
        </CustomDialog>
    );
}