import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green, grey } from '@mui/material/colors';

interface InputFileDialogSuccessProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    uploadInfo: {
        processed: number;
        uploaded: number;
        errors: number;
    }
}

function InputFileSuccessDialog({ open, setOpen, uploadInfo } : InputFileDialogSuccessProps) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
            <Dialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, pr: 3, background: green[300] }}>
                    <CheckCircleOutlineIcon sx={{
                        position: 'relative',
                        top: 6,
                        color: (theme) => theme.palette.mode === 'dark' ? grey[100] : grey[800],
                        mr: 1
                    }}
                    />
                    File has been uploaded
                </DialogTitle>
                <DialogContent dividers sx={{ pl: 6 }}>
                    <Typography>
                        Processed rows: {uploadInfo.processed}
                    </Typography>
                    <Typography>
                        Uploaded rows: {uploadInfo.uploaded}
                    </Typography>
                    <Typography>
                        Errors: {uploadInfo.errors}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}>
                    <Box>
                        <Button sx={{
                            color: (theme) => theme.palette.mode === 'dark' ? grey[100] : grey[800],
                            borderColor: green[300],
                            '&:hover': { borderColor: (theme) => theme.palette.mode === 'dark' ? green[100] : green[600] }
                        }}
                            variant="outlined"
                            autoFocus onClick={handleClose}
                        >
                            Ok
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
    );
}
export default InputFileSuccessDialog;

