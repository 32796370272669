import { AccountBalance, LocalShipping, People, SignalCellularAlt, TrendingUp } from '@mui/icons-material';
import { grey, indigo } from '@mui/material/colors';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LayoutProvider, LeftDrawerItem } from 'wcz-layout';
import packageJson from '../package.json';
import IE from './pages/IE';
import NPI from './pages/NPI';
import PMC from './pages/PMC';
import Summary from './pages/Summary';
import TE from './pages/TE';

const leftDrawerItems: LeftDrawerItem[] = [
    { title: "PMC", path: "/", icon: <TrendingUp fontSize="large" /> },
    { title: "NPI", path: "/npi", icon: <People fontSize="large" /> },
    { title: "IE", path: "/ie", icon: <LocalShipping fontSize="large" /> },
    { title: "TE", path: "/te", icon: <AccountBalance fontSize="large" /> },
    { title: "Summary", path: "/summary", icon: <SignalCellularAlt fontSize="large" /> },
];

export default function App() {
    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={indigo[500]} secondaryColor={grey[500]} leftDrawerItems={leftDrawerItems}>
                <Routes>
                    <Route path='/' element={<PMC />} />
                    <Route path='/npi' element={<NPI />} />
                    <Route path='/ie' element={<IE />} />
                    <Route path='/te' element={<TE />} />
                    <Route path='/summary' element={<Summary />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
}