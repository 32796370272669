import { DataGridPremium, GridColDef, GridRowModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import EditableHeader from "../components/common/EditableHeader";
import { apiUrl } from "../utils/BaseUrl";

export default function NPI() {
    const { changeTitle, snackbar } = useContext(LayoutContext);

    useEffect(() => changeTitle("Summary"), []);

    const { data = [], isLoading, refetch } = useQuery(["summary"], ({ signal }) => fetchGet(`${apiUrl}/v1/summary/`, signal));

    const columns: GridColDef[] = useMemo(() => [
        { field: 'sku', headerName: "SKU", width: 120, },
        { field: 'l11', headerName: "L11", width: 170, },
        {
            field: 'l10', headerName: "L10", width: 170, valueGetter: ({ value, row }) => {
                const values: string[] = [value];
                if (row.pnl10Second) values.push(row.pnl10Second);
                return values;
            },
            renderCell: ({ value }) =>
                <Fragment>
                    {value[0]}
                    {value[1] && <br />}
                    {value[1]}
                </Fragment>
        },
        { field: 'model', headerName: "Model", width: 120, },
        { field: 'grSr', headerName: "GR/SR", type: 'singleSelect', valueOptions: ['GR', 'SR'], cellClassName: ({ value }) => value === "GR" ? 'text-warning' : 'text-secondary', },
        { field: 'snL11', headerName: "SN L11", width: 170, },
        { field: 'mo', headerName: "MO", width: 130, },
        { field: 'stage', headerName: "Stage", },
        { field: 'npiFlag', headerName: "NPI", type: 'boolean', },
        { field: 'ieflag', headerName: "IE", type: 'boolean', },
        { field: 'faiFlag', headerName: "FAI", type: 'boolean', editable: true, renderHeader: EditableHeader, },
        { field: 'daysInProduction', headerName: "Days in production", width: 150, type: 'number', },
        { field: 'comment', headerName: "Comment", width: 250, editable: true, renderHeader: EditableHeader, },
    ] as GridColDef[], []);

    const processRowUpdate = useCallback(async (newRow: GridRowModel<GridValidRowModel>, oldRow: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        let updatedColumn: string = "";
        let value: string = "";

        if (oldRow.faiFlag !== newRow.faiFlag) {
            updatedColumn = "faiFlag";
            value = newRow.faiFlag;
        } else if (oldRow.comment !== newRow.comment) {
            updatedColumn = "comment";
            value = newRow.comment;
        }

        const req = {
            column: updatedColumn,
            value: value,
            id: newRow.id,
            schema: "Summary"
        };

        fetchPost(`${apiUrl}/v1/update/?column=${req.column}&value=${req.value}&id=${req.id}&schema=${req.schema}`, null)
            .catch(error => {
                if (typeof (error) === "string") {
                    snackbar({ message: error, severity: "error" });
                    refetch();
                }
            });

        return newRow;
    }, []);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} processRowUpdate={processRowUpdate}
                slotProps={{ toolbar: { viewKey: "summary" } as GridToolbarProps }} />
        </TableContainer>
    );
}