import { ContentCopy } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import EditableHeader from "../components/common/EditableHeader";
import { apiUrl } from "../utils/BaseUrl";
import InputFileUpload from "../components/common/InputFileUpload";

export default function PMC() {
    const { changeTitle, snackbar, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle("PMC"), []);

    const { data = [], isLoading, refetch } = useQuery(["pmc"], ({ signal }) => fetchGet(`${apiUrl}/v1/pmc/`, signal));

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="ContentCopy" icon={<ContentCopy />} label={t("Duplicate")} onClick={handleOnDuplicateClick(params)} showInMenu />,
            ],
        },
        { field: 'msfSku', headerName: "MSF-SKU", width: 120, },
        {
            field: 'pnL10', headerName: "PN L10", width: 170, valueGetter: ({ value, row }) => {
                const values: string[] = [value];
                if (row.pnl10Second) values.push(row.pnl10Second);
                return values;
            },
            renderCell: ({ value }) =>
                <Fragment>
                    {value[0]}
                    {value[1] && <br />}
                    {value[1]}
                </Fragment>
        },
        { field: 'pnL11', headerName: "PN L11", width: 170, },
        { field: 'model', headerName: "Model", width: 120, },
        { field: 'grSr', headerName: "GR/SR", type: 'singleSelect', valueOptions: ['GR', 'SR'], cellClassName: ({ value }) => value === "GR" ? 'text-warning' : 'text-secondary', },
        { field: 'releaseToProduction', headerName: "Release to production", width: 190, type: 'boolean', editable: true, renderHeader: EditableHeader, },
        { field: 'realStartDate', headerName: "Real start", width: 130, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'status', headerName: "Status", width: 120, },
        { field: 'materialReadiness', headerName: "Material Readiness", width: 170, type: 'date', editable: true, renderHeader: EditableHeader, valueGetter: ({ value }) => value && new Date(value), },
        { field: 'routingFlag', headerName: "Routing", type: 'boolean', editable: true, renderHeader: EditableHeader, },
        { field: 'calCostFlag', headerName: "Cal cost", type: 'boolean', },
        { field: 'readyToReleaseFlag', headerName: "Ready to release MO", width: 150, type: 'boolean', },
        { field: 'moStatus', headerName: "MO Status", width: 130, editable: true, renderHeader: EditableHeader, },
        { field: 'mo', headerName: "MO", width: 130, editable: true, renderHeader: EditableHeader, },
        { field: 'exfMinus5', headerName: "EXF - 5", width: 150, type: 'date', valueGetter: ({value}) => value && new Date(value), },
        { field: 'proposedDockDate', headerName: "Proposed Dock", width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'exfDate', headerName: "EXF", width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'commentL11', headerName: "Comments L11", width: 700, },
        { field: 'commentL10', headerName: "Comments L10", width: 700, },
    ] as GridColDef[], [i18n.language]);

    const handleOnDuplicateClick = useCallback((params: any) => () => duplicate(params.id), []);

    const { mutate: duplicate } = useMutation((id: string) => fetchPost(`${apiUrl}/v1/duplicate/?id=${id}&schema=PMC`, null), {
        onSuccess: () => {
            refetch();
            snackbar({ message: t("Duplicated") });
        }
    });

    const processRowUpdate = useCallback(async (newRow: GridRowModel<GridValidRowModel>, oldRow: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        let updatedColumn: string = "";
        let value: string = "";

        if (newRow.releaseToProduction !== oldRow.releaseToProduction) {
            updatedColumn = "releaseToProduction";
            value = newRow.releaseToProduction;
        }
        else if (newRow.materialReadiness !== oldRow.materialReadiness) {
            updatedColumn = "materialReadiness";
            value = moment(newRow.materialReadiness).formatDate();
        }
        else if (newRow.routingFlag !== oldRow.routingFlag) {
            updatedColumn = "routingFlag";
            value = newRow.routingFlag;
        }
        else if (newRow.moStatus !== oldRow.moStatus) {
            updatedColumn = "moStatus";
            value = newRow.moStatus;
        }
        else if (newRow.mo !== oldRow.mo) {
            updatedColumn = "mo";
            value = newRow.mo;
        }

        const req = {
            column: updatedColumn,
            value: value,
            id: newRow.id,
            schema: "PMC"
        };

        fetchPost(`${apiUrl}/v1/update/?column=${req.column}&value=${req.value}&id=${req.id}&schema=${req.schema}`, null)
            .catch(error => {
                if (typeof (error) === "string") {
                    snackbar({ message: error, severity: "error" });
                    refetch();
                }
            });

        return newRow;
    }, []);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} processRowUpdate={processRowUpdate} slots={{ toolbar: GridToolbar }} loading={isLoading}
                slotProps={{ toolbar: { viewKey: "pmc", hideAddRecord: true, items: [<InputFileUpload key="inputFileUpload" />] } as GridToolbarProps }} />
        </TableContainer>
    );
}