import { ContentCopy, Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import { fetchDelete, fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import EditableHeader from "../components/common/EditableHeader";
import { apiUrl } from "../utils/BaseUrl";

export default function NPI() {
    const { changeTitle, snackbar, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle("TE"), []);

    const { data = [], isLoading, refetch } = useQuery(["te"], ({ signal }) => fetchGet(`${apiUrl}/v1/te/`, signal));

    const { mutate: remove } = useMutation((id: string) => fetchDelete(`${apiUrl}/v1/delete/?id=${id}&schema=TE`), {
        onSuccess: () => {
            refetch();
            snackbar({ message: t("Deleted") });
        }
    });

    const handleOnDeleteClick = useCallback((params: any) => () => remove(params.id), []);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="ContentCopy" icon={<ContentCopy />} label={t("Duplicate")} onClick={handleOnDuplicateClick(params)} showInMenu />,
                <GridActionsCellItem key="delete" icon={<Delete />} label={t("Delete")} onClick={handleOnDeleteClick(params)} showInMenu />,
            ],
        },
        { field: 'grSr', headerName: "GR/SR", type: 'singleSelect', valueOptions: ['GR', 'SR'], cellClassName: ({ value }) => value === "GR" ? 'text-warning' : 'text-secondary', },
        { field: 'model', headerName: "Model", width: 150, },
        { field: 'modelFamily', headerName: "Model family", width: 150, },
        { field: 'msfSku', headerName: "MSF-SKU", width: 120, },
        { field: 'l11', headerName: "L11", width: 170, },
        {
            field: 'l10', headerName: "L10", width: 170, valueGetter: ({ value, row }) => {
                const values: string[] = [value];
                if (row.pnl10Second) values.push(row.pnl10Second);
                return values;
            },
            renderCell: ({ value }) =>
                <Fragment>
                    {value[0]}
                    {value[1] && <br />}
                    {value[1]}
                </Fragment>
        },
        { field: 'rackSn', headerName: "Rack SN", width: 170, editable: true, renderHeader: EditableHeader, },
        {
            field: 'crdInfo', headerName: "CRD Info", width: 170,
            renderCell: ({ value }) => <Box onClick={handleCrdClick(value)} sx={{ color: 'primary.main', cursor: 'pointer' }}>{value?.split(";")[0]}</Box>
        },
        { field: 'teRevision', headerName: "Rev", width: 130, editable: true, renderHeader: EditableHeader, },
        { field: 'testLocation', headerName: "Test Location", width: 120, },
        { field: 'rackPot', headerName: "Rack POT", width: 120, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'mfgTest', headerName: "MFG Test", width: 120, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'bslTest', headerName: "BSL Test", width: 120, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'bslVersion', headerName: "BSL Version", width: 150, },
        { field: 'l11BoomBase', headerName: "L11 Bom Base", width: 160, type: 'date', valueGetter: ({ value }) => value && new Date(value), editable: true, renderHeader: EditableHeader, },
        { field: 'l10BoomBase', headerName: "L10 Bom Base", width: 160, type: 'date', valueGetter: ({ value }) => value && new Date(value), editable: true, renderHeader: EditableHeader, },
        { field: 'l11Db', headerName: "L11 DB", width: 120, type: 'date', valueGetter: ({ value }) => value && new Date(value), editable: true, renderHeader: EditableHeader, },
        { field: 'teComment', headerName: "Comment", width: 250, editable: true, renderHeader: EditableHeader, },
    ] as GridColDef[], [i18n.language]);

    const handleOnDuplicateClick = useCallback((params: any) => () => duplicate(params.id), []);

    const { mutate: duplicate } = useMutation((id: string) => fetchPost(`${apiUrl}/v1/duplicate/?id=${id}&schema=TE`, null), {
        onSuccess: () => {
            refetch();
            snackbar({ message: t("Duplicated") });
        }
    });

    const handleCrdClick = useCallback((value: string) => () => {
        if (value)
            window.open(value.split(";")[1]);
    }, []);

    const processRowUpdate = useCallback(async (newRow: GridRowModel<GridValidRowModel>, oldRow: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        let updatedColumn: string = "";
        let value: string = "";

        if (oldRow.rackSn !== newRow.rackSn) {
            updatedColumn = "rackSn";
            value = newRow.rackSn;
        } else if (oldRow.teRevision !== newRow.teRevision) {
            updatedColumn = "teRevision";
            value = newRow.teRevision;
        } else if (oldRow.l11BoomBase !== newRow.l11BoomBase) {
            updatedColumn = "l11BoomBase";
            value = moment(newRow.l11BoomBase).formatDate();
        } else if (oldRow.l10BoomBase !== newRow.l10BoomBase) {
            updatedColumn = "l10BoomBase";
            value = moment(newRow.l10BoomBase).formatDate();
        } else if (oldRow.l11Db !== newRow.l11Db) {
            updatedColumn = "l11Db";
            value = moment(newRow.l11Db).formatDate();
        } else if (oldRow.teComment !== newRow.teComment) {
            updatedColumn = "teComment";
            value = newRow.teComment;
        }

        const req = {
            column: updatedColumn,
            value: value,
            id: newRow.id,
            schema: "TE"
        };

        fetchPost(`${apiUrl}/v1/update/?column=${req.column}&value=${req.value}&id=${req.id}&schema=${req.schema}`, null)
            .catch(error => {
                if (typeof (error) === "string") {
                    snackbar({ message: error, severity: "error" });
                    refetch();
                }
            });

        return newRow;
    }, []);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} processRowUpdate={processRowUpdate} slots={{ toolbar: GridToolbar }} loading={isLoading}
                slotProps={{ toolbar: { viewKey: "te", hideAddRecord: true } as GridToolbarProps }} />
        </TableContainer>
    );
}
