import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchPost } from 'wcz-layout';
import React, { useState } from 'react';
import { apiUrl } from '../../utils/BaseUrl';
import InputFileSuccessDialog from './InputFileSuccessDialog';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload() {

  const [open, setOpen] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({
    processed: 0,
    uploaded: 0,
    errors: 0
  });

  const queryClient = useQueryClient();

  const { mutate: uploadExcel } = useMutation((data: FormData) => fetchPost(apiUrl + "/v1/upload", data), {
    onSuccess: (response: Response) => {
      response.json().then((data: { processed: number, uploaded: number, errors: number }) => {
        queryClient.invalidateQueries(["pmc"], {exact: false});
        setUploadInfo({ processed: data.processed, uploaded: data.uploaded, errors: data.errors});
        setOpen(true);
      });
    }
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      uploadExcel(formData);
    }
  };

  return (
    <>
    <Button
      component="label"
      role={undefined}
      variant="text"
      tabIndex={-1}
      size="small"
      startIcon={<CloudUploadIcon />}
    >
      UPLOAD IOS FILE
      <VisuallyHiddenInput type="file" onChange={handleFileChange} />
    </Button>
    <InputFileSuccessDialog
      open={open}
      setOpen={setOpen}
      uploadInfo={uploadInfo}
    />
    </>
  );

}
