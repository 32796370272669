import { DataGridPremium, GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchGet, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import TasksDialog, { IETaskParams } from "../components/ie/TasksDialog";
import { apiUrl } from "../utils/BaseUrl";

export default function NPI() {
    const [detailParams, setDetailParams] = useState<IETaskParams>({} as IETaskParams);
    const { changeTitle } = useContext(LayoutContext);

    useEffect(() => changeTitle("IE"), []);

    const { data = [], isLoading, refetch } = useQuery(["ie"], ({ signal }) => fetchGet(`${apiUrl}/v1/ie/`, signal));

    const columns: GridColDef[] = useMemo(() => [
        { field: 'sku', headerName: "SKU", width: 120, },
        { field: 'l11', headerName: "L11", width: 170, },
        {
            field: 'l10', headerName: "L10", width: 170, valueGetter: ({ value, row }) => {
                const values: string[] = [value];
                if (row.pnl10Second) values.push(row.pnl10Second);
                return values;
            },
            renderCell: ({ value }) =>
                <Fragment>
                    {value[0]}
                    {value[1] && <br />}
                    {value[1]}
                </Fragment>
        },
        { field: 'model', headerName: "Model", width: 120, },
        { field: 'grSr', headerName: "GR/SR", type: 'singleSelect', valueOptions: ['GR', 'SR'], cellClassName: ({ value }) => value === "GR" ? 'text-warning' : 'text-secondary', },
        { field: 'l10Sku', headerName: "L10 sku", width: 150, },
        { field: 'releaseDate', headerName: "Release to production", width: 170, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'modelFamily', headerName: "Model family", width: 150, },
        { field: 'status', headerName: "Status", width: 150, type: 'boolean', valueGetter: ({ value }) => value === "1" },
    ] as GridColDef[], []);

    const handleOnRowDoubleClick = useCallback((params: GridRowParams) => setDetailParams({ l10: params.row.l10, l11: params.row.l11, modelFamily: params.row.modelFamily }), []);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading}
                    slotProps={{ toolbar: { viewKey: "ie" } as GridToolbarProps }} onRowDoubleClick={handleOnRowDoubleClick} />
            </TableContainer>

            <TasksDialog params={detailParams} setParams={setDetailParams} refetch={refetch} />
        </Fragment>
    );
}
