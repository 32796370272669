import { ContentCopy, Create } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import EditableHeader from "../components/common/EditableHeader";
import EditLinkDialog, { EditLinkDialogModel } from "../components/common/EditLinkDialog";
import { apiUrl } from "../utils/BaseUrl";

export default function NPI() {
    const [editLink, setEditLink] = useState<EditLinkDialogModel>({} as EditLinkDialogModel);
    const { changeTitle, snackbar, t } = useContext(LayoutContext);

    useEffect(() => changeTitle("NPI"), []);

    const { data = [], isLoading, refetch } = useQuery(["npi"], ({ signal }) => fetchGet(`${apiUrl}/v1/npi/`, signal));

    const { mutate: duplicate } = useMutation((id: string) => fetchPost(`${apiUrl}/v1/duplicate/?id=${id}&schema=NPI`, null), {
        onSuccess: () => {
            refetch();
            snackbar({ message: t("Duplicated") });
        }
    });

    const handleOnDuplicateClick = useCallback((params: any) => () => duplicate(params.id), []);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="ContentCopy" icon={<ContentCopy />} label={t("Duplicate")} onClick={handleOnDuplicateClick(params)} showInMenu />,
            ],
        },
        { field: 'msfSku', headerName: "MSF-SKU", width: 120, },
        { field: 'l11', headerName: "L11", width: 170, description: "R-L11 - routing", },
        {
            field: 'l10', headerName: "L10", width: 170, valueGetter: ({ value, row }) => {
                const values: string[] = [value];
                if (row.pnl10Second) values.push(row.pnl10Second);
                return values;
            },
            renderCell: ({ value }) =>
                <Fragment>
                    {value[0]}
                    {value[1] && <br />}
                    {value[1]}
                </Fragment>
        },
        { field: 'model', headerName: "Model", width: 120, },
        { field: 'grSr', headerName: "GR/SR", type: 'singleSelect', valueOptions: ['GR', 'SR', 'None'], editable: true, renderHeader: EditableHeader, cellClassName: ({ value }) => value === "GR" ? 'text-warning' : 'text-secondary', },
        { field: 'l10Sku', headerName: "L10 sku", width: 150, },
        { field: 'modelFamily', headerName: "Model family", width: 150, },
        {
            field: 'crd', headerName: "CRD", width: 170,
            renderCell: ({ value, row }) =>
                <Box sx={{ display: 'contents' }}>
                    <Box onClick={handleCrdClick(value)} sx={{ color: 'primary.main', cursor: 'pointer' }}>{value?.split(";")[0]}</Box>
                    <IconButton size="small" sx={{ ml: value && 1 }} onClick={handleCrdEditClick(value, row)}>
                        <Create fontSize="small" />
                    </IconButton>
                </Box>,
        },
        { field: 'teRevision', headerName: "TE Revision", width: 140, editable: true, renderHeader: EditableHeader, },
        { field: 'releaseDate', headerName: "Release to production", width: 170, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'mfL10', headerName: "L10", type: 'boolean', },
        { field: 'mfL11', headerName: "L11", type: 'boolean', },
        { field: 'routingL10', headerName: "R-L10", type: 'boolean', },
        { field: 'routingL11', headerName: "R-L11", type: 'boolean', },
        { field: 'configL10', headerName: "C-L10", type: 'boolean', description: "C-L10 - UPN configuration", },
        { field: 'configL11', headerName: "C-L11", type: 'boolean', description: "C-L11 - UPN configuration", },
        { field: 'unitInfoL10', headerName: "UI-L10", type: 'boolean', description: "UI-L10 - Unit information", },
        { field: 'unitInfoL11', headerName: "UI-L11", type: 'boolean', description: "UI-L11 - Unit information", },
        { field: 'barcodeL11', headerName: "BC-L10", type: 'boolean', description: "BC-L10 - bar code" },
        { field: 'snL11', headerName: "SNL L11", width: 170, editable: true, renderHeader: EditableHeader, },
        { field: 'stage', headerName: "Stage", },
        { field: 'realStartDate', headerName: "Real start date", width: 130, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'bslPassDate', headerName: "BSL Pass", width: 130, type: 'date', valueGetter: ({ value }) => value && new Date(value), },
        { field: 'kpi', headerName: "KPI", type: 'number', },
        { field: 'comment', headerName: "Comment", width: 250, editable: true, renderHeader: EditableHeader, },
        { field: 'releaseTe', headerName: "Release to TE", width: 150, type: 'boolean', editable: true, renderHeader: EditableHeader, },
    ] as GridColDef[], []);

    const handleCrdClick = useCallback((value: string) => () => {
        if (value)
            window.open(value.split(";")[1]);
    }, []);

    const handleCrdEditClick = useCallback((value: string, row: any) => () =>
        setEditLink({
            column: "crd",
            id: row.id,
            schema: "NPI",
            value: value,
        }), []);

    const processRowUpdate = useCallback(async (newRow: GridRowModel<GridValidRowModel>, oldRow: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        let updatedColumn: string = "";
        let value: string = "";

        if (newRow.grSr !== oldRow.grSr) {
            updatedColumn = "grSr";
            value = newRow.grSr === "None" ? "" : newRow.grSr;
        } else if (newRow.snL11 !== oldRow.snL11) {
            updatedColumn = "snL11";
            value = newRow.snL11;
        } else if (newRow.teRevision !== oldRow.teRevision) {
            updatedColumn = "teRevision";
            value = newRow.teRevision;
        } else if (newRow.comment !== oldRow.comment) {
            updatedColumn = "comment";
            value = newRow.comment;
        } else if (newRow.releaseTe !== oldRow.releaseTe) {
            updatedColumn = "releaseTe";
            value = newRow.releaseTe;
        }

        const req = {
            column: updatedColumn,
            value: value,
            id: newRow.id,
            schema: "NPI"
        };

        fetchPost(`${apiUrl}/v1/update/?column=${req.column}&value=${req.value}&id=${req.id}&schema=${req.schema}`, null)
            .catch(error => {
                if (typeof (error) === "string") {
                    snackbar({ message: error, severity: "error" });
                    refetch();
                }
            });

        return newRow;
    }, []);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={data} columns={columns} processRowUpdate={processRowUpdate} slots={{ toolbar: GridToolbar }} loading={isLoading}
                    slotProps={{ toolbar: { viewKey: "npi", hideAddRecord: true } as GridToolbarProps }} />
            </TableContainer>

            <EditLinkDialog model={editLink} setModel={setEditLink} refetch={refetch} />
        </Fragment>
    );
}
