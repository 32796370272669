import { Button, TextField } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchPost, LayoutContext } from "wcz-layout";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "./CustomDialog";

export interface EditLinkDialogModel {
    column: string,
    value: string,
    id: string,
    schema: string,
}

interface EditLinkDialogProps {
    model: EditLinkDialogModel,
    setModel: (model: EditLinkDialogModel) => void,
    refetch: () => void
}

export default function EditLinkDialog(props: EditLinkDialogProps) {
    const { model, setModel, refetch } = props;
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const { t, snackbar } = useContext(LayoutContext);

    useEffect(() => {
        if (model.value) {
            const splittedValue = model.value?.split(";");
            setText(splittedValue[0]);
            setLink(splittedValue[1]);
        }
    }, [model.value]);

    const open = useMemo(() => !!model.id && !!model.schema, [model]);
    const onClose = useCallback(() => setModel({} as EditLinkDialogModel), []);

    const handleTextChange = useMemo(() => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setText(e.target.value), []);
    const handleLinkChange = useMemo(() => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setLink(e.target.value), []);

    const handleSubmit = useCallback(() => {
        const req: EditLinkDialogModel = { ...model, value: "" };

        if (text && link)
            req.value = `${text};${link}`;

        fetchPost(`${apiUrl}/v1/update/?column=${req.column}&value=${req.value}&id=${req.id}&schema=${req.schema}`, null)
            .then(() => {
                refetch();
                onClose();
            })
            .catch(error => {
                if (typeof (error) === "string")
                    snackbar({ message: error, severity: "error" });

                refetch();
                onClose();
            });
    }, [text, link, model]);

    return (
        <CustomDialog open={open} onClose={onClose} title={t("EditLink")} color="primary" dialogActions={[
            <Button key="submit" onClick={handleSubmit}>{t("Submit")}</Button>
        ]}>
            <TextField variant="standard" fullWidth label="Text" margin="dense" multiline value={text} onChange={handleTextChange} />
            <TextField variant="standard" fullWidth label="Link" margin="dense" multiline value={link} onChange={handleLinkChange} />
        </CustomDialog>
    );
}